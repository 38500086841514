import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '~/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium focus-ring-primary',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary/90 disabled:opacity-75 disabled:pointer-events-none',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:opacity-75 disabled:pointer-events-none',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground disabled:opacity-75 disabled:pointer-events-none',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80 disabled:opacity-75 disabled:pointer-events-none',
        ghost:
          'hover:bg-accent hover:text-accent-foreground disabled:opacity-75 disabled:pointer-events-none',
        link: 'text-primary underline-offset-4 hover:underline disabled:opacity-75 disabled:pointer-events-none',
        map: 'bg-background hover:bg-accent hover:text-accent-foreground shadow-md disabled:opacity-75 disabled:pointer-events-none',
      },
      size: {
        default: 'h-10 px-4 py-2',
        none: '',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8 text-base',
        icon: 'h-10 w-10',
        map: 'h-10 w-10',
        'map-sm': 'h-8 w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, type = 'button', ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
